import React from 'react'
import TypographyPro from 'themes/TypographyPro'
import styled from 'styled-components'
import SmartLink from './SmartLink'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import type _Application from 'mobx/Application'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { kebabCase } from 'lodash-es'

const StyledSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 959px) {
		padding: 5px 40px;
	}
`

const StyledTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 959px) {
		margin: 15px 0;
	}
`

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)<{ $open: boolean }>`
	display: none;

	@media (max-width: 576px) {
		display: block;
		color: var(--optionalBackground);
		transform: rotate(${({ $open }) => ($open ? `180deg` : `0`)});
	}
`

const StyledSectionTitle = styled(TypographyPro)`
	color: var(--optionalBackground);
`

const StyledItemSection = styled.div`
	flex-direction: column;
	display: flex;
`

/*
 * This is to style the OneTrust cookie consent button, !important has to be used to override OneTrust's styling which
 * has higher specificity.
 */
const StyledCookieConsentLink = styled(TypographyPro)`
	border: initial !important;
	padding: 0 0 15px !important;
	text-decoration: none;
	color: var(--optionalBackground) !important;
	background-color: transparent !important;
`

interface Props {
	Application: typeof _Application
	idx: number
	sectionName: string
	sectionItems: { itemType: string; itemId: string; text: string }[]
	clickHandler: (sectionName: string) => void
	showItems: boolean
	isGeneratedStatically?: boolean
}

const ECommerceFooterSection = (props: Props) => {
	const { Application, sectionName, sectionItems = [], clickHandler, showItems, idx, isGeneratedStatically = false } = props

	return (
		<StyledSectionContainer>
			<StyledTitleContainer onClick={() => clickHandler(sectionName)}>
				<StyledSectionTitle component="div" variant="BodyRegularHighlighted">
					{sectionName}
				</StyledSectionTitle>
				<StyledArrowDropDownIcon $open={showItems} />
			</StyledTitleContainer>
			<StyledItemSection>
				{showItems &&
					sectionItems.map((sectionItem, index) =>
						sectionItem.itemType === 'cookieConsent' ? (
							<StyledCookieConsentLink
								key={sectionItem.itemId}
								id="ot-sdk-btn"
								className="ot-sdk-show-settings"
								variant="BodySmall"
								component="a"
							>
								{sectionItem.text}
							</StyledCookieConsentLink>
						) : (
							<SmartLink
								openInSameBrowserTab={sectionItem.itemType !== 'external'}
								sectionItem={sectionItem}
								isGeneratedStatically={isGeneratedStatically}
								key={sectionItem.itemId}
								style={{
									textDecoration: 'none',
									paddingBottom: '15px',
									color: 'var(--optionalBackground)',
								}}
								testId={`footer-link-${index}`}
								id={`${Application.page}-page-end-bar-${kebabCase(sectionItem.itemId)}`}
							>
								{sectionItem.text}
							</SmartLink>
						)
					)}
			</StyledItemSection>
		</StyledSectionContainer>
	)
}

export default inject('Application')(observer(ECommerceFooterSection))
